// src/locales/zh.js
export default {
  //header, sidemenu
  company: "关于我们",
  solutions: "解决方案",
  technology: "研究/技术",
  contact2: "位置指南",

  //submenu
  ceomessage: "CEO 致辞",
  ability: "企业力量",
  history: "企业履历",
  patent: "认证与专利",
  gfyjiggy: "知己知彼",
  sleeptech: "睡眠科技",
  electroceuticals: "电子药物",
  kiosk: "自助服务机",
  fallDetector: "跌倒探测器",
  edgeBox: "边缘框",
  mEdgeBox: "移动边缘盒",
  contact: "位置指南",

  //footer
  copyright: "Copyright © 2024 Gfyhealth Inc.，版权所有。",
  companyNameTitle: "公司名称",
  companyname: "Gfyhealth Inc.",
  registrationNum: "营业执照号码",
  address: "京畿道城南市盆唐区板桥路289号8楼2栋5室",
  tel: "电话",
  email: "电子邮件",

  //home
  home_header_title1:
    "每个人健康的最佳选择，一个为健康制定不会随着时间而改变的标准的地方。",
  home_header_title2: "体验GFY，它能持续保证您的幸福。",
  home_title1: "GfyHealth通过基于AI的综合医疗保健解决方案支持更健康的生活",
  home_title2: "",
  home_title3: "GfyHealth旨在通过创新的医疗保健解决方案保持增长",
  home_title4: "",
  home_mission1: "创业项目计划进军中华圈市场",
  home_mission1_1: "与当地企业签署联合开发及开拓市场的MOU",
  home_mission2: "通过创业项目“数字治疗剂”的批准，",
  home_mission2_1: "计划将其拓展为专业医疗产品",
  home_mission3: "通过阶段性推出后续产品，",
  home_mission3_1: "在今后两年内实现3倍以上的销售目标",
  home_mission4: "计划五年内通过IPO实现Exit",

  home_solutions1: "了解GfyHealth的主要解决方案",
  home_solutions2: "",
  home_gfyjiggy: "知己知彼",
  home_gfyjiggyInfo: "认知障碍（痴呆）预防、确认、针对性训练综合服务",
  home_sleeptech: "睡眠科技",
  home_sleeptechInfo: "应用触觉传感器的智能床垫",
  home_electroceuticals: "什么是电子药物",
  home_electroceuticalsInfo: "改善糖尿病的电子药物系统",
  moreInfo: "了解更多",

  home_introduceTitle1: "与GfyHealth一起创造故事",
  home_introduceTitle2: "",
  home_companyIntroduce: "COMPANY",
  home_companyIntroduceInfo1: "GfyHealth将成为一家与客户一起成长和成功的公司",
  home_companyIntroduceInfo2: "",
  home_contactIntroduce: "CONTACT",
  home_contactIntroduceInfo1: "GfyHealth将引导您舒适地来到详细的位置",
  home_contactIntroduceInfo2: "",

  //ceo message
  ceomessage_title1: "大家好",
  ceomessage_title2: "我是GfyHealth(株) 的代表理事李泰贤。",
  ceomessage_paragraph1_1:
    "首先,向关心和访问我们GfyHealth的所有人表示深深的感谢。",
  ceomessage_paragraph1_1_1:
    "GfyHealth一直致力于在医疗保健领域提供创新解决方案和最佳服务。",
  ceomessage_paragraph1_2:
    "我们的目标是改善健康和生活质量，为此我们基于最新的技术和研究开发了各种健康产品和服务。",
  ceomessage_paragraph2_1: "GfyHealth 的成长是基于客户的信任和厚爱。",
  ceomessage_paragraph2_2: "我们将持续倾听客户的声音，为健康生活创造新价值。",
  ceomessage_paragraph2_2_2:
    "我们承诺,不仅是单纯地提供产品,还将作为能够为提高顾客生活质量做出贡献的医疗保健伙伴发挥作用。",
  ceomessage_paragraph3:
    "今后也请大家一如既往地支持和关心,为了大家的健康幸福的生活,GfyHealth会一直陪伴大家。",
  ceomessage_paragraph4: "谢谢。",
  ceomessage_paragraph5: "GfyHealth(株) 代表理事 ",
  ceomessage_paragraph6: "李泰贤",

  //ability
  ability_img: "基于AI / HW的综合生活（护理）解决方案",
  ability_b2g1: "创业项目",
  ability_b2g2: "痴呆预防/训练程序App.",
  ability_b2g3: "高性能老人定位器",
  ability_b2g4: "配备NFC的指甲贴老年人二维码",
  ability_b2g5: "疗养老人用跌倒感应器",
  ability_b2g6: "【新】针对长期疗养换着的智能床垫",
  ability_b2b1: "员工综合健康管理终端机",
  ability_b2b2: "工作中预测心脏异常的智能贴片",
  ability_b2b3: "工作环境监测用EdgeBox",
  ability_b2b4: "工作现场用跌倒感应器 ",
  ability_b2c1: "【新】糖尿病改善电子药物系统",
  ability_b2c2: "苔藓过滤空气净化器",
  ability_b2c3: "提升睡眠质量的智能床垫",

  //history
  history_2019_1: "成立法人",
  history_2019_2: "2020年 被选为初期创业最优秀科研项目",
  history_2019_2_1: "'基于人工智能的智能型 痴呆症评估康复训练用应用程序'",
  history_2020_1: "2020年 被选为中小风险企业部扶持科研项目",
  history_2020_2: "'具有故障预测和维护功能的智能灾害预测预警系统ㅍ'",
  history_2021_1: "风险企业认证",
  history_2021_2: "企业附设研究所",
  history_2021_3: "2021年 被选为产业资源部扶持科研项目",
  history_2021_3_1: "'基于产业职群行动模式分析的个性化员工健康管理解决方案'",
  history_2023_1: "注册专门研究事业单位",
  history_2023_2: "2023年 被选为设计创新扶持项目",
  history_2023_2_1: "'用于治疗糖尿病的电子药物非侵入性耳神经刺激装置'",
  history_2023_2_2: "",
  history_2023_3: "推出新项目",
  history_2023_3_1: "糖尿病改善电子药物系统",
  history_2023_3_2: " 徘徊感应系统",
  history_2023_3_2_1: "- 老年人护理服务",
  history_2023_3_2_2: "- 预防孤独死（智能床垫）",
  history_2024_1: "入选2024年未来融合技术先锋项目",
  history_2024_1_1: " ‘微流体装置联动微重力模拟系统的开发’",

  //patent
  patent1: "商业登记证",
  patent2: "创业企业确认",
  patent3: "企业研究院认可证书",
  patent4: "专业研究事业登记证",
  patent5: "一种可管理饮水量的智能水瓶及其控制方法",
  patent5_1: "",
  patent6: "缩短的便携式自动注射装置",
  patent7: "使用伴侣机器人的银护理系统",
  patent8: "无线骨骼肌刺激的模式传输方法",
  patent8_1: "",
  patent9: "使用压阻式触觉传感器的老年护理系统",
  patent9_1: "",
  patent10: "一种痴呆症患者的管理方法及装置",
  patent11: "基于人工智能的痴呆诊断方法及装置",
  patent11_1: "",
  patent12: "电子医疗系统与医疗服务技术",
  patent12_1: "",
  patent13: "糖尿病改善电子医疗系统及方法",

  //contact
  location: "位置指南",
  addressTitle: "地址",
  address1: "京畿道城南市盆唐区板桥路289号8楼2栋5室",
  address2: "",
  transpotationTitle: "公共交通",
  subwayTitle: "地铁",
  subwayInfo: "新盆堂板桥站4号出口板桥Techno Valley乘坐巴士",
  subwayInfo2: "",
  busTitle: "公交",
  busInfo: "Botdeul交流道或三平桥站下车",
  busInfo2: "",

  //gfyjiggy
  gfyjiggy_section1_title: "Gfy Jiggy是指?",
  gfyjiggy_section1_info1:
    "得到韩国优秀医院和研究机构的技术转让，由人工智能IoT解决方案专门企业知己知彼（株）所开发的 认知障碍（痴呆）预防、确认、",
  gfyjiggy_section1_info2: "针对性",
  gfyjiggy_section1_info3: "训练综合服务",
  gfyjiggy_section1_info4: "通过政府扶持研发项目实现技术优化升级",
  gfyjiggy_section2_title: "知己知彼 的差异性",
  gfyjiggy_section2_info1: "推荐通过细分认知能力评价的针对性训练",
  gfyjiggy_section3_title: "知己知彼效应",
  gfyjiggy_section3_info1: "通过量身定做型分析, 集中训练掉落的技能",
  gfyjiggy_section3_subtitle1: "病人管理",
  gfyjiggy_section3_subtitle1_info:
    "治疗师帮助患者预防和改善痴呆, 以掌握患者的问题并有效解决。",
  gfyjiggy_section3_subtitle2: "单训",
  gfyjiggy_section3_subtitle2_info:
    "帮助患者根据痴呆情况，只重复进行特定的单一训练。",
  gfyjiggy_section3_subtitle3: "组成训练",
  gfyjiggy_section3_subtitle3_info:
    "根据患者的痴呆情况，结合各种训练，帮助患者开展综合训练。",
  gfyjiggy_section3_subtitle4: "患者记录纸",
  gfyjiggy_section3_subtitle4_info:
    "记录每次训练结果,帮助医生更准确地确认痴呆情况。",
  gfyjiggy_section4_title: "预防痴呆、诊断、改善服务",
  gfyjiggy_section4_subtitle: "通过量身定做型分析，可集中训练掉落的技能！",
  gfyjiggy_section4_startbtn: "下载",

  //sleeptech
  sleeptech_section1_title: "睡眠技术是指",
  sleeptech_section1_info: "应用自主开发的触觉传感器的智能床垫",
  sleeptech_section2_title: "应用触觉传感器优势与特点",
  sleeptech_section2_info1: "应用技术检测比例电阻变化",
  sleeptech_section2_info2:
    "通过在力传感器接触面上以点状分布电阻，能够识别运动路径和变化量的传感器设计",
  sleeptech_section2_info3:
    "H/W、S/W设计，存储单位时间内接触力的强度和分布分析，以分析和比较力载荷",
  sleeptech_section2_info4: "导电材料控制、导电油墨制造和压阻传感器设计",
  sleeptech_section2_info5: "尺寸、负载范围和精度可以调整以适应用户的应用。",
  sleeptech_section3_title: "睡眠科技的特点",
  sleeptech_section3_info1_1: "薄膜式触觉传感器",
  sleeptech_section3_info1_2: "具有可定制尺寸和精度的触觉传感器",
  sleeptech_section3_info2: "需要测量部分负荷和负荷变化的业务领域",
  sleeptech_section3_info3_1: "判断是否与床垫接触及其位置",
  sleeptech_section3_info3_2: "检查躺卧时间和运动程度",
  sleeptech_section4_title: "睡眠科技-服务目标",
  sleeptech_section4_subtitle: "独居家庭健康管理服务",
  sleeptech_section4_info1: "单人家庭",
  sleeptech_section4_info1_1: "初步检测孤独死亡、检测坠落",
  sleeptech_section4_info2: "老年福利用品",
  sleeptech_section4_info2_1: "预防褥疮、远程监控",
  sleeptech_section4_info3: "睡眠状态运动",
  sleeptech_section4_info3_1: "监测睡眠健康用品",

  //electroceuticals
  electroceuticals_title1: "人工智能应用",
  electroceuticals_title2: "与",
  electroceuticals_title3: "电子药物",
  electroceuticals_title4: "使用技术",
  electroceuticals_title5: "个性化饮食",
  electroceuticals_section1_title:
    "通过分析体质和生活方式，提供定制化电子医疗解决方案",
  electroceuticals_section2_title:
    "通过集成应用程序和链接硬件输入和分析个人信息",
  electroceuticals_section3_title: "基于电子医疗的定制饮食可穿戴设备推荐",
  electroceuticals_section4_title: "食欲管理可穿戴设备",
  electroceuticals_section4_info: "饥饿激素(ghrelin)调节和食欲抑制作用",
  electroceuticals_section5_title: "血糖管理可穿戴设备",
  electroceuticals_section5_info: "刺激迷走神经、缓解血糖波动的作用",
  electroceuticals_section6_title: "脂肪管理可穿戴设备",
  electroceuticals_section6_info: "微电流刺激刺激及脂肪生成抑制作用",
  electroceuticals_section7_title1: "体重和血糖恢复正常后",
  electroceuticals_section7_title2: "后续护理",
  electroceuticals_section7_title3: "维护计划",
  electroceuticals_section7_icon1_title: "锻炼计划",
  electroceuticals_section7_icon1_info: "定制锻炼计划建议和指导",
  electroceuticals_section7_icon2_title: "饮食计划",
  electroceuticals_section7_icon2_info: "维持正常体重和血糖水平的饮食计划指南",
  electroceuticals_section7_icon3_title: "睡眠计划",
  electroceuticals_section7_icon3_info: "保持健康的最佳睡眠计划指南",

  //kiosk
  kiosk_title: "医疗保健亭",
  kiosk_section1_title: "什么是医疗保健亭",
  kiosk_section1_info1:
    "它可以应用于建筑、制造、运输等行业从业人员上下班途中的个人身份识别验证，以及通过简单的问卷调查来检查其日常健康状况，观察每日和每周的变化情况",
  kiosk_section1_info2:
    "测量并记录老年人、体弱者及普通民众的平时健康状况，及早发现异常情况",
  kiosk_section1_info3:
    "提供预防工人安全事故的指导。例如，当测量酒精含量或其他测量值与平常不同或超出标准时，提供工作期间注意事项的指导，例如注意/警告、建议休息和禁止上班",
  kiosk_section2_title: "主要测量项目",
  kiosk_section2_icon1: "身高",
  kiosk_section2_icon2: "重量",
  kiosk_section2_icon3: "温度",
  kiosk_section2_icon4: "血压",
  kiosk_section2_icon5: "体脂（体水分）",
  kiosk_section2_icon6: "血氧饱和度",
  kiosk_section3_title: "主要功能及使用形式",
  kiosk_section3_icon1: "触摸屏",
  kiosk_section3_icon2: "语音指导",
  kiosk_section3_icon3: "酒精测试仪（单独安装）",
  kiosk_section3_icon4: "通信（WiFi、蓝牙等）",
  kiosk_section4_title: "应用业务领域",
  kiosk_section4_info1: "为建筑业和制造业",
  kiosk_section4_info1_1: "等所有行业的工人提供健康管理",
  kiosk_section4_info1_2: "",
  kiosk_section4_info2: "老年人、长者及公众的自我检查和保健",
  kiosk_section4_info3:
    "测量项目——可添加或排除身高、体重、体脂/体质量、血压、血氧饱和度、酒精状况等测量项目",
  kiosk_section5_title: "业绩证明书",

  //fallDetector
  fallDetector_title: "跌倒探测器",
  fallDetector_section1_title: "什么是跌倒检测器",
  fallDetector_section1_info1:
    "跌倒检测器是一种可以检测人体姿势突然变化的设备，有助于降低长期受伤的风险",
  fallDetector_section1_info2:
    "适用于建筑、制造、物流配送、119救援人员在户外进行紧急和不规则作业",
  fallDetector_section1_info3:
    "一旦发生坠落事故，应尽快通知周围的人，并确定反复发生的地点，并警告危险，以帮助防止事故再次发生",
  fallDetector_section2_title: "跌倒检测器数据存储方法",
  fallDetector_section2_info1: "使用内置闪存存储加速度计/陀螺仪数据",
  fallDetector_section2_info2:
    "当发生一个事件时，如果在事件发生前后 1.65 到 3.3 秒内保存 165 到 330 个加速度计/陀螺仪数据，则最多可保存 31 个事件；如果在事件发生前后 3.3 到 6.6 秒内保存 15 个事件，则最多可保存 15 个事件",
  fallDetector_section2_info3:
    "添加串行闪存作为备用（当需要大于内置闪存容量的数据时）",
  fallDetector_section2_info4:
    "定期（每小时或每两小时）从手机获取数据时存储 15 个事件",
  fallDetector_section3_title: "数据传输应用软件‘我的健康笔记本’",
  fallDetector_section3_info1:
    "将日常生活中获取的必要个人信息从跌倒检测器传输到所需服务器的应用软件",
  fallDetector_section3_info2:
    "自动保存从跌倒检测器收集的跌倒结果，并在特定时间通过智能手机应用程序自动或手动将保存的数据传输到所需的服务器",
  fallDetector_section3_info3:
    "通过应用软件查看每日、每周等时间段的数据，用于个人健康管理",
  fallDetector_section3_info4:
    "当发生坠落事故时，它会被检测到并自动保存和传输，从而可以识别经常发生坠落的地点",
  fallDetector_section3_info5:
    "为了实时检测坠落事故，通知周围环境，存储、传输并传递给第三方，需要与服务器进行实时通信",
  fallDetector_section4_title: "应用业务领域",
  fallDetector_section4_info1: "",
  fallDetector_section4_info1_1: "有坠落风险的所有职业的工人",
  fallDetector_section4_info2:
    "行动不便的人士，例如老年人、体弱者、病人以及预计在跌倒时受伤风险较高的人群",
  fallDetector_section5_title: "业绩证明书",

  //edgeBox
  edgeBox_title: "环境传感 EdgeBox",
  edgeBox_section1_title: "什么是环境传感器边缘盒",
  edgeBox_section1_info1:
    "制造业、建筑业等户外工业场所的固定工作环境（温度/湿度、细尘、CO2、有害气体等）的测量",
  edgeBox_section1_info2:
    "通过固定在墙壁上，首先由边缘盒子本身分析工作环境中不断收集和存储的数据，并将收集到的数据传输到服务器，以实现对工作环境的分析",
  edgeBox_section1_info3:
    "通过工作环境管理者监控系统提供基于风险因素意识的指导（改变工作时间、调整工作量、提供休息时间等）",
  edgeBox_section2_title: "环境传感器边缘盒操作方法",
  edgeBox_section3_title: "Edgebox 功能",
  edgeBox_section3_icon1: "数据中继和加密",
  edgeBox_section3_icon2: "Linux 服务器 SSH 自动化",
  edgeBox_section3_icon3: "PLC远程控制及数据采集",
  edgeBox_section3_icon4: "WAS 服务器",
  edgeBox_section3_icon5: "有线/无线通信",
  edgeBox_section4_title: "应用业务领域",
  edgeBox_section4_info1: "",
  edgeBox_section4_info1_1: "所有工业职业，包括建筑业和制造业",
  edgeBox_section4_info2: "暴露于危险环境因素的室外工作场所",
  edgeBox_section4_info3: "",
  edgeBox_section4_info3_1: "需要通风的室内封闭空间",
  edgeBox_section4_info4:
    "需要测量周围环境、二氧化碳、温度、湿度、TVOC、细尘（PM10、PM2.5）、CCTV（可选）的区域。如有必要，可以添加/排除测量项目",
  edgeBox_section5_title: "业绩证明书",

  //mEdgeBox
  mEdgeBox_title: "环境传感移动EdgeBox",
  mEdgeBox_section1_title: "什么是环境传感 Mobile EdgeBox",
  mEdgeBox_section1_info1:
    "119急救、车辆等室外工业现场的移动工作环境测量（温度/湿度、细尘、CO2、有害气体）",
  mEdgeBox_section1_info2:
    "便携式、结构紧凑、可拆卸、可拆卸的边缘盒，相对简单，有利于短期测量或测量移动工人的工作环境",
  mEdgeBox_section1_info3:
    "与固定边缘盒GHEB系列相比，它的优点是能够测量移动工人的环境因素",
  mEdgeBox_section1_info4:
    "它通过将其固定在必要的位置（例如车辆内部）来测量环境因素，并取出便携式存储卡，通过将工作环境中收集和存储的数据连续传输到服务器来分析工作环境",
  mEdgeBox_section1_info5:
    "通过工作环境管理者监控系统，根据风险因素识别提供指导（工作安排的变化、工作量的调整、休息时间的授予等）",
  mEdgeBox_section2_title: "如何操作环境传感器移动边缘盒",
  mEdgeBox_section3_title: "移动边箱功能",
  mEdgeBox_section3_info1:
    "假定车辆的 220V 电​​源在点火开关关闭时关闭，因此使用备用电源",
  mEdgeBox_section3_info2:
    "如果可以恒电，则需要测量mini PC或传感器模块的功耗并计算可以恒电多长时间（交流功率计另购）",
  mEdgeBox_section3_info3: "如果您不保存照片，则可以存储数十年的数据",
  mEdgeBox_section3_info4: "迷你 PC 必须在指定的数据传输时间连接到网络",
  mEdgeBox_section4_title: "应用业务领域",
  mEdgeBox_section4_info1: "边箱、119救护车活动区域",
  mEdgeBox_section4_info1_1: "内外的车辆移动工作环境",
  mEdgeBox_section4_info1_2: "卡车司机等",
  mEdgeBox_section4_info2: "需要通风的车辆密闭空间环境测量",
  mEdgeBox_section4_info3: "根据现场情况立即安装、收集",
  mEdgeBox_section5_title: "业绩证明书",
};
