import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/company/ceomessage",
    name: "ceomessage",
    component: () => import("../views/company/ceomessageView.vue"),
  },
  {
    path: "/company/ability",
    name: "ability",
    component: () => import("../views/company/abilityView.vue"),
  },
  {
    path: "/company/history",
    name: "history",
    component: () => import("../views/company/historyView.vue"),
  },
  {
    path: "/company/patent",
    name: "patent",
    component: () => import("../views/company/patentView.vue"),
  },
  {
    path: "/solutions/gfyjiggy",
    name: "gfyjiggy",
    component: () => import("../views/solutions/gfyjiggyView.vue"),
  },
  {
    path: "/solutions/sleeptech",
    name: "sleeptech",
    component: () => import("../views/solutions/sleeptechView.vue"),
  },
  {
    path: "/solutions/electroceuticals",
    name: "electroceuticals",
    component: () => import("../views/solutions/electroceuticalsView.vue"),
  },
  {
    path: "/technology/kiosk",
    name: "kiosk",
    component: () => import("../views/technology/kioskView.vue"),
  },
  {
    path: "/technology/fallDetector",
    name: "fallDetector",
    component: () => import("../views/technology/fallDetectorView.vue"),
  },
  {
    path: "/technology/edgeBox",
    name: "edgeBox",
    component: () => import("../views/technology/edgeBoxView.vue"),
  },
  {
    path: "/technology/mEdgeBox",
    name: "mEdgeBox",
    component: () => import("../views/technology/mEdgeBoxView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact/contactView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // 이전에 저장된 위치로 스크롤 (브라우저 뒤로가기)
    } else {
      return { top: 0 }; // 페이지 전환 시 스크롤 맨 위로
    }
  },
});

export default router;
