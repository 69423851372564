// src/locales/en.js
export default {
  //header, sidemenu
  company: "COMPANY",
  solutions: "SOLUTIONS",
  technology: "TECHNOLOGY",
  contact2: "CONTACT",
  ceomessage: "CEO Message",
  ability: "Business Capabilities",
  history: "Company History",
  patent: "Certifications & Patents",
  gfyjiggy: "Gfy Jiggy",
  sleeptech: "SleepTech",
  electroceuticals: "Electroceuticals",
  kiosk: "Kiosk",
  fallDetector: "Fall Detector",
  edgeBox: "EdgeBox",
  mEdgeBox: "Mobile EdgeBox",
  contact: "Location",

  //footer
  copyright: "Copyright © 2024 Gfyhealth. Inc., ALL RIGHTS RESERVED.",
  companyNameTitle: "Company Name",
  companyname: "Gfyhealth Inc.",
  registrationNum: "Business Registration Number",
  address:
    "Gyeonggi Startup Campus, Room 5, Building 2, 8th Floor, 20, Pangyo-ro 289beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do",
  tel: "Tel",
  email: "E-mail",

  //home
  home_header_title1:
    "Providing consistent, reliable health solutions that stand the test of time for everyone.",
  home_header_title2:
    "Experience GFY, which continuously ensures your well-being.",
  home_title1: "GfyHealth supports healthier lives",
  home_title2: "with AI-based integrated healthcare solutions",
  home_title3: "GfyHealth aims to sustain growth",
  home_title4: "through innovative healthcare solutions",

  home_mission1: "Plans to advance into China",
  home_mission1_1:
    "Planning MoU for joint development and market entry with local companies",
  home_mission2:
    "We will expand our business to the medical product market after the approval of the Digital Medicine",
  home_mission2_1: "",
  home_mission3:
    "Planning more than triple sales within the next two years by gradually launching follow-up products",
  home_mission3_1: "",
  home_mission4: "Aiming for an IPO within 5 years",

  home_solutions1: "Meet GfyHealth's",
  home_solutions2: "Key Solutions",
  home_gfyjiggy: "Gfy Jiggy",
  home_gfyjiggyInfo:
    "An integrated service for cognitive impairment(dementia) prevention, diagnosis, and customized training",
  home_sleeptech: "SleepTech",
  home_sleeptechInfo: "Smart Mattress using tactile sensors",
  home_electroceuticals: "Electroceuticals",
  home_electroceuticalsInfo: "Electroceutical System for Diabetes Improvement",
  moreInfo: "find out more",

  home_introduceTitle1: "Make a story",
  home_introduceTitle2: "with GfyHealth",
  home_companyIntroduce: "COMPANY",
  home_companyIntroduceInfo1: "GfyHealth will become a company ",
  home_companyIntroduceInfo2: "that grows and succeeds with its customers",
  home_contactIntroduce: "CONTACT",
  home_contactIntroduceInfo1: "GfyHealth will guide you ",
  home_contactIntroduceInfo2: "to the detailed location comfortably",

  //ceo message
  ceomessage_title1: "Greetings.",
  ceomessage_title2: "I'm Lee Tae-hyun, CEO of GfyHealth Co., Ltd.",
  ceomessage_paragraph1_1:
    "First, thank you for all people who visited GfyHealth. ",
  ceomessage_paragraph1_1_1:
    "GfyHealth is always committed to delivering innovative solutions and the best services in healthcare.",
  ceomessage_paragraph1_2:
    "Our goal is to improve health and quality of life, and for this purpose, we are developing healthcare products and services based on the latest technologies and research.",
  ceomessage_paragraph2_1: "GfyHealth has grown based on your trust and love.",
  ceomessage_paragraph2_2:
    "We will continue to listen to customers' voices and create new values for a healthy life. ",
  ceomessage_paragraph2_2_2:
    "We promise not just to provide products, but to do our part as healthcare partners who can contribute to improving the quality of life for our customers.",
  ceomessage_paragraph3:
    "We ask for your continued support and interest, and GfyHealth will always be with you for your healthy and happy life.",
  ceomessage_paragraph4: "Thank you.",
  ceomessage_paragraph5: "CEO of GfyHealth Co., Ltd ",
  ceomessage_paragraph6: "Lee Tae-hyun",

  //ability
  ability_img: "AI-based / HW-based Health Care Solution",
  ability_b2g1: "First Invention",
  ability_b2g2: "App.Dementia Prevention / Training App.",
  ability_b2g3: "High performance patient position sensor",
  ability_b2g4: "NFC-equipped, nail-attached patient identification tag",
  ability_b2g5: "Fall detector for the elderly",
  ability_b2g6: "[New] Smart mat for long-term care patients",
  ability_b2b1: "Integrated Health Care Kiosk",
  ability_b2b2: "Heart abnormality prediction patch for workers",
  ability_b2b3: "Edge box for workplace monitoring",
  ability_b2b4: "Fall Detectors for workplaces",
  ability_b2c1: "[New] Electroceuticals for diabetes improvement ",
  ability_b2c2: "moss filter air purifier",
  ability_b2c3: "smart mat for better sleep",

  //history
  history_2019_1: "Incorporation",
  history_2019_2:
    "Selected as the best project for the 2020 initial start-up package",
  history_2019_2_1:
    "'AI-based dementia diagnosis and rehabilitation training app'",
  history_2020_1:
    "Selected for 2020 Ministry of SMEs and Startups support project",
  history_2020_2:
    "'Intelligent disaster warning system with failure prediction and maintenance function’",
  history_2021_1: "Certified as a venture company",
  history_2021_2: "Affiliated research lab open",
  history_2021_3:
    "Selected as a 2021 Ministry of Commerce, Industry and Energy support project ",
  history_2021_3_1:
    "'Personalized health care solution based on analysis of the behavior patterns of industrial workers’",
  history_2023_1: "Registered as a professional research enterprise",
  history_2023_2: "Selected for 2023 design innovation support project",
  history_2023_2_1: "'Non-invasive ear nerve stimulation device",
  history_2023_2_2:
    "for the application of electronic medicine to improve diabetes’",
  history_2023_3: "New business launch",
  history_2023_3_1: "Electronic medicine system for diabetes",
  history_2023_3_2: "loitering detection system",
  history_2023_3_2_1: "- Senior Care Service",
  history_2023_3_2_2: "- lonely death Prevention(smart mattress)",
  history_2024_1:
    "2024 Future Convergence Technology Pioneer Project Selection",
  history_2024_1_1:
    " ‘Development of a microgravity simulation system linked to a microfluidic device’",

  //patent
  patent1: "Business registration certificate",
  patent2: "Venture Business Confirmation Certificate",
  patent3: "Certificate of Corporate Research Institute",
  patent4: "Professional Research Business Registration Certificate",
  patent5:
    "Smart water bottle capable of managing water intake and its control method",
  patent5_1: "",
  patent6: "Shortened portable autoinjector",
  patent7: "Silver care system using companion robots",
  patent8:
    "Pattern transmission method for wireless skeletal muscle stimulation",
  patent8_1: "",
  patent9: "Elderly care system using a piezoresistive tactile sensor",
  patent9_1: "",
  patent10: "Methods and devices for managing dementia patients",
  patent11:
    "Dementia diagnosis method and device based on artificial intelligence",
  patent11_1: "",
  patent12: "Electronic medicine system and healthcare service technology",
  patent12_1: "",
  patent13: "Diabetes improvement electronic medicine system and method",

  //contact
  location: "Location",
  addressTitle: "Address",
  address1: "Gyeonggi Startup Campus,",
  address2:
    "Room 5, Building 2, 8th Floor, 20, Pangyo-ro 289beon-gil, Bundang-gu, Seongnam-si, Gyeonggi-do",
  transpotationTitle: "Public Transportation",
  subwayTitle: "Subway",
  subwayInfo: "Take the bus at Pangyo Techno Valley ",
  subwayInfo2: "at Exit 4 of Shinbundang Pangyo Station",
  busTitle: "Bus",
  busInfo: "Get off at Botdeul Overpass ",
  busInfo2: "or Sampyeong Bridge stop",

  //gfyjiggy
  gfyjiggy_section1_title: "What is Gfy Jiggy?",
  gfyjiggy_section1_info1:
    "Developed by GP Health Inc, AI/IoT company, with technology transfer from hospitals and research institutes in Korea. Cognitive disorder (dementia) prevention, diagnose, customized training ",
  gfyjiggy_section1_info2: "integrated",
  gfyjiggy_section1_info3: "service",
  gfyjiggy_section1_info4: "Technology development with government support",
  gfyjiggy_section2_title: "Gfy Jiggy feature",
  gfyjiggy_section2_info1:
    "Customized training recommendation through segmented assessment system! ",
  gfyjiggy_section3_title: "Gfy Jiggy effects",
  gfyjiggy_section3_info1:
    "Intensive functional training through customized analysis",
  gfyjiggy_section3_subtitle1: "Patient Management",
  gfyjiggy_section3_subtitle1_info:
    "The therapist helps prevent and improve dementia in patients to identify and effectively solve their problems.",
  gfyjiggy_section3_subtitle2: "Single training",
  gfyjiggy_section3_subtitle2_info:
    "Depending on the patient's dementia situation, it helps to perform only a single specific training repeatedly.",
  gfyjiggy_section3_subtitle3: "Configuration training",
  gfyjiggy_section3_subtitle3_info:
    "It helps to perform complex training by combining various training according to the patient's dementia situation.",
  gfyjiggy_section3_subtitle4: "Patient's Record",
  gfyjiggy_section3_subtitle4_info:
    "Record each training result to help doctors more accurately to identify dementia situations.",
  gfyjiggy_section4_title:
    "Dementia Prevention, Diagnosis, and Improvement Services",
  gfyjiggy_section4_subtitle:
    "Customized analysis enables intensive training of functions away from it!",
  gfyjiggy_section4_startbtn: "Download",

  //sleeptech
  sleeptech_section1_title: "What is Sleep Tech",
  sleeptech_section1_info: "Smart Mattress using self-developed tactile sensor",
  sleeptech_section2_title: "The functions of tactile sensors",
  sleeptech_section2_info1:
    "Application of technology to detect professional changes in resistance",
  sleeptech_section2_info2:
    "Design a sensor that distributes resistance in the form of a dot on the force sensor contact surface to recognize the path of movement and the amount of change",
  sleeptech_section2_info3:
    "H/W, S/W design that stores the strength and distribution analysis of the force in contact in hours to analyze and compare the force load",
  sleeptech_section2_info4:
    "Conductive material control, conductive ink manufacturing and piezoresistive sensor design",
  sleeptech_section2_info5:
    "Size, load range, and precision can be adjusted to suit the user's purpose",
  sleeptech_section3_title: "Sleep Tech Features",
  sleeptech_section3_info1_1: "Film-type tactile sensor",
  sleeptech_section3_info1_2:
    "Tactile sensor capable of size and precision customization",
  sleeptech_section3_info2:
    "Business fields that require measurement of partial load and load changes",
  sleeptech_section3_info3_1:
    "Determines if there is contact with the mattress",
  sleeptech_section3_info3_2:
    "Check the time you lie down and the degree of movement",
  sleeptech_section4_title: "SleepTech Business goal",
  sleeptech_section4_subtitle:
    "Health care services for single elderly households",
  sleeptech_section4_info1: "Safety for single-person households",
  sleeptech_section4_info1_1:
    "Early detection of death from loneliness, fall detection",
  sleeptech_section4_info2: "Elderly welfare products",
  sleeptech_section4_info2_1: "Bedsore prevention, remote monitoring",
  sleeptech_section4_info3: "Sleep state movement",
  sleeptech_section4_info3_1: "Monitoring sleep health products",

  //electroceuticals
  electroceuticals_title1: "A personalized diet system ",
  electroceuticals_title2: "using",
  electroceuticals_title3: "artificial intelligence-based apps",
  electroceuticals_title4: "and",
  electroceuticals_title5: "electroceuticals technology",
  electroceuticals_section1_title:
    "Provide customized electronic medicine solutions by analyzing your constitution and lifestyle",
  electroceuticals_section2_title:
    "Enter and analyze personal information through integrated apps and linked hardware",
  electroceuticals_section3_title:
    "Recommendation of personalized diet wearable based on ectroceuticals",
  electroceuticals_section4_title: "Appetite management wearable",
  electroceuticals_section4_info:
    "Regulates hunger hormone (ghrelin) and suppresses appetite",
  electroceuticals_section5_title: "Blood Sugar Management Wearable",
  electroceuticals_section5_info:
    "Vagus nerve stimulation and glycemic control effects",
  electroceuticals_section6_title: "Fat management wearable",
  electroceuticals_section6_info:
    "Microcurrent stimulation and the effect of inhibiting lipogenesis",
  electroceuticals_section7_title1: "After normal weight and blood sugar",
  electroceuticals_section7_title2: "a follow-up care",
  electroceuticals_section7_title3: "Maintenance program",
  electroceuticals_section7_icon1_title: "Exercise Program",
  electroceuticals_section7_icon1_info:
    "Customized exercise program recommendations and guidance",
  electroceuticals_section7_icon2_title: "Diet program",
  electroceuticals_section7_icon2_info:
    "Guide to a diet program to maintain normal weight and blood sugar levels",
  electroceuticals_section7_icon3_title: "Sleep program",
  electroceuticals_section7_icon3_info:
    "Guide to the optimal sleep program for maintaining health",

  //kiosk
  kiosk_title: "HealthCare Kiosk",
  kiosk_section1_title: "What is HealthCare Kiosk",
  kiosk_section1_info1:
    "It can be used to verify the personal identity of workers in industries such as construction, manufacturing, and transportation on their way to and from get off work, as well as to check their daily health status through simple questionnaires and observe daily and weekly changes",
  kiosk_section1_info2:
    "Measure and record the usual health status of the elderly, the weak and the general public to detect abnormal conditions early",
  kiosk_section1_info3:
    "Provide guidance on preventing worker safety accidents. For example, on precautions during work, such as cautions/warnings, rest breaks, and prohibitions on going to work when the measured alcohol content or other measured values ​​are different from usual or exceed the standard",
  kiosk_section2_title: "Key Measurement Items",
  kiosk_section2_icon1: "Height",
  kiosk_section2_icon2: "Weight",
  kiosk_section2_icon3: "Temperature",
  kiosk_section2_icon4: "Blood pressure",
  kiosk_section2_icon5: "Body fat (Body water)",
  kiosk_section2_icon6: "Blood oxygen saturation",
  kiosk_section3_title: "Main functions and usage forms",
  kiosk_section3_icon1: "Touchscreen",
  kiosk_section3_icon2: "Voice guidance",
  kiosk_section3_icon3: "Breathalyzer (attached separately)",
  kiosk_section3_icon4: "Communication (WiFi, Bluetooth, etc.)",
  kiosk_section4_title: "Application Business Areas",
  kiosk_section4_info1: "Providing health management for ",
  kiosk_section4_info1_1: "workers in all industries",
  kiosk_section4_info1_2: "including construction and manufacturing",
  kiosk_section4_info2:
    "Self-examination and health care for the elderly, seniors and general public",
  kiosk_section4_info3:
    "You can add or exclude measurements such as height, weight, body fat/mass, blood pressure, blood oxygen saturation, alcohol status, etc",
  kiosk_section5_title: "Performance Certificate",

  //fallDetector
  fallDetector_title: "Fall Detector",
  fallDetector_section1_title: "What is Fall detector",
  fallDetector_section1_info1:
    "Fall detectors are devices that detect sudden changes in a person's posture and help reduce the risk of long-term injury",
  fallDetector_section1_info2:
    "Suitable for construction, manufacturing, logistics delivery, and 119 rescue workers who perform emergency and irregular work outdoors",
  fallDetector_section1_info3:
    "In the event of a fall accident, notify those around you as soon as possible, identify places where it occurs repeatedly, and warn of danger to help prevent recurring accidents",
  fallDetector_section2_title: "Fall detector data storage method",
  fallDetector_section2_info1:
    "Use built-in flash memory to store Acc/Gyro data",
  fallDetector_section2_info2:
    "When one event occurs, up to 31 events are saved when 165 to 330 Acc/Gyro data are saved for 1.65 to 3.3 seconds before and after, and up to 15 events are saved when 3.3 to 6.6 seconds are applied",
  fallDetector_section2_info3:
    "Add serial flash memory as a spare (when data larger than the built-in flash memory capacity is required)",
  fallDetector_section2_info4:
    "Stores 15 events when taking data periodically (every hour or every two hours) from the mobile phone",
  fallDetector_section3_title:
    "Data transmission application software 'My Health Notebook'",
  fallDetector_section3_info1:
    "Application software that transmits necessary personal information acquired in daily life from a fall detector to a desired server",
  fallDetector_section3_info2:
    "Automatically save the fall results collected from the fall detector and automatically or manually transmit the saved data to the desired server via the smartphone app at a specific time",
  fallDetector_section3_info3:
    "Check data by period, such as daily and weekly, through application software and use it for personal health management",
  fallDetector_section3_info4:
    "When a fall accident occurs, it is detected and automatically saved and transmitted, allowing identification of places where falls frequently occur",
  fallDetector_section3_info5:
    "In order to detect a fall accident in real time, notify the surroundings, store, transmit, and deliver to a third party, real-time communication with the server is required",
  fallDetector_section4_title: "Application Business Areas",
  fallDetector_section4_info1: "",
  fallDetector_section4_info1_1:
    "Workers in all occupations with a risk of falling",
  fallDetector_section4_info2:
    "People with mobility difficulties, such as the elderly, frail, and patients, and groups expected to be at greater risk of damage in the event of a fall ",
  fallDetector_section5_title: "Performance Certificate",

  //edgeBox
  edgeBox_title: "Environmental sensing EdgeBox",
  edgeBox_section1_title: "What is an environmental sensor edge box",
  edgeBox_section1_info1:
    "Measurement of fixed working environment (temperature/humidity, fine dust, CO2, harmful gases, etc.) in outdoor industrial sites such as manufacturing and construction",
  edgeBox_section1_info2:
    "By fixing it to the wall, the data continuously collected and stored in the work environment is first analyzed by the edge box itself, and the collected data is transmitted to the server to enable analysis of the work environment",
  edgeBox_section1_info3:
    "Provide guidance based on risk factor awareness through the work environment manager monitoring system (change in work schedule, adjust workload, provide break time, etc)",
  edgeBox_section2_title: "How to operate the environmental sensor edge box",
  edgeBox_section3_title: "Edgebox feature",
  edgeBox_section3_icon1: "Data relay and encryption",
  edgeBox_section3_icon2: "Linux Server SSH Automation",
  edgeBox_section3_icon3: "PLC remote control and data acquisition",
  edgeBox_section3_icon4: "WAS Server",
  edgeBox_section3_icon5: "Wired/wireless communication",
  edgeBox_section4_title: "Application Business Areas",
  edgeBox_section4_info1: "",
  edgeBox_section4_info1_1:
    "All industrial occupations including construction and manufacturing",
  edgeBox_section4_info2:
    "Outdoor workplaces exposed to hazardous environmental factors",
  edgeBox_section4_info3: "",
  edgeBox_section4_info3_1: "Indoor enclosed spaces requiring ventilation",
  edgeBox_section4_info4:
    "Fields requiring measurement of surrounding environment, carbon dioxide, temperature, humidity, TVOC, fine dust (PM10, PM2.5), CCTV (optional). Measurement items can be added/excluded as needed",
  edgeBox_section5_title: "Performance Certificate",

  //mEdgeBox
  mEdgeBox_title: "Environmental sensing Mobile EdgeBox",
  mEdgeBox_section1_title: "What is an environmental sensing Mobile EdgeBox",
  mEdgeBox_section1_info1:
    "119 rescue team, vehicle, etc. Mobile work environment measurement in outdoor industrial sites (temperature/humidity, fine dust, CO2, harmful gases)",
  mEdgeBox_section1_info2:
    "Mobile, compact, detachable edge box, suitable for relatively simple and short-time measurements or measuring the working environment of moving workers",
  mEdgeBox_section1_info3:
    "Advantages of measuring environmental factors of moving workers compared to fixed edge box GHEB series",
  mEdgeBox_section1_info4:
    "By attaching it to a necessary location, such as inside a vehicle, it measures environmental factors, and by detaching the removable memory card, it continuously collects and stores data in the work environment and transmits it to the server to analyze the work environment",
  mEdgeBox_section1_info5:
    "Provide guidance based on risk factor awareness through the work environment manager monitoring system (change in work schedule, adjust workload, provide break time, etc)",
  mEdgeBox_section2_title:
    "How to operate the environmental sensor mobile edge box",
  mEdgeBox_section3_title: "Mobile Edge Box Features",
  mEdgeBox_section3_info1:
    "Assuming that the vehicle's 220V supply is turned off when the engine is turned off, reserve power is used",
  mEdgeBox_section3_info2:
    "If constant power supply is possible, it is necessary to measure the power consumption of the mini PC or sensor module and calculate how long constant power supply is possible(AC power meter separate)",
  mEdgeBox_section3_info3:
    "If you don't save photos, you can store decades worth of data",
  mEdgeBox_section3_info4:
    "The mini PC must be connected to the network during the designated data transmission time",
  mEdgeBox_section4_title: "Application Business Areas",
  mEdgeBox_section4_info1: "",
  mEdgeBox_section4_info1_1: "Edgebox for mobile work environments",
  mEdgeBox_section4_info1_2:
    "inside/outside the 119 ambulance operation area, truck drivers, etc",
  mEdgeBox_section4_info2:
    "Measuring the environment of enclosed spaces in vehicles requiring ventilation",
  mEdgeBox_section4_info3:
    "Immediate installation and collection according to on-site conditions",
  mEdgeBox_section5_title: "Performance Certificate",
};
