<template>
  <Header />
  <div class="router-view">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import Header from "./components/HeaderView.vue";
import Footer from "./components/FooterView.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
@import "./assets/css/app.css";
@import "./assets/css/fonts.css";
</style>
