import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import AOS from "aos";
import "aos/dist/aos.css";
import { createI18n } from "vue-i18n";

// 언어 파일 불러오기
import en from "./locales/en";
import ko from "./locales/ko";
import zh from "./locales/zh";

// i18n 인스턴스 생성
const i18n = createI18n({
  locale: "ko", // 기본 언어 설정
  fallbackLocale: "ko", // 기본 언어로 대체할 언어 설정
  messages: {
    ko,
    en,
    zh,
  },
});
// Vue 앱 생성
const app = createApp(App);

// AOS 초기화
AOS.init({
  duration: 1200, // 애니메이션 지속 시간
  easing: "ease-in-out", // 애니메이션 이징 함수
  once: false, // 애니메이션을 한 번만 실행할지 여부
});

// Vue 앱에 AOS 글로벌 속성 추가
app.config.globalProperties.$aos = AOS;

// i18n 및 기타 플러그인 사용
app.use(i18n).use(store).use(router).mount("#app");
