<template>
  <!-- sidemenu -->
  <div class="sidemenu">
    <div class="sidemenu-closebtn-container" @click="closeSideMenu">
      <img src="../assets/close_btn.png" alt="close btn" />
    </div>
    <div class="sidemenu-section">
      <div class="all-sidemenu">
        <div class="sidemenu1">
          <div
            class="sidemenu1-title"
            :class="{ active: activeSideMenu === 'company' }"
            @click="toggleSideMenuDropdown('company')"
          >
            {{ $t("company") }}
          </div>
          <div class="sidemenu1-submenus" v-if="activeSideMenu === 'company'">
            <router-link to="/company/ceomessage" @click="closeSideMenu">{{
              $t("ceomessage")
            }}</router-link>
            <router-link to="/company/ability" @click="closeSideMenu">{{
              $t("ability")
            }}</router-link>
            <router-link to="/company/history" @click="closeSideMenu">{{
              $t("history")
            }}</router-link>
            <router-link to="/company/patent" @click="closeSideMenu">{{
              $t("patent")
            }}</router-link>
          </div>
        </div>
        <div class="sidemenu2">
          <div
            class="sidemenu2-title"
            :class="{ active: activeSideMenu === 'solutions' }"
            @click="toggleSideMenuDropdown('solutions')"
          >
            {{ $t("solutions") }}
          </div>
          <div class="sidemenu2-submenus" v-if="activeSideMenu === 'solutions'">
            <router-link
              to="/solutions/electroceuticals"
              @click="closeSideMenu"
              >{{ $t("electroceuticals") }}</router-link
            >
            <router-link to="/solutions/sleeptech" @click="closeSideMenu">{{
              $t("sleeptech")
            }}</router-link>
            <router-link to="/solutions/gfyjiggy" @click="closeSideMenu">{{
              $t("gfyjiggy")
            }}</router-link>
          </div>
        </div>
        <div class="sidemenu3">
          <div
            class="sidemenu3-title"
            :class="{ active: activeSideMenu === 'technology' }"
            @click="toggleSideMenuDropdown('technology')"
          >
            {{ $t("technology") }}
          </div>
          <div
            class="sidemenu3-submenus"
            v-if="activeSideMenu === 'technology'"
          >
            <router-link to="/technology/kiosk" @click="closeSideMenu">{{
              $t("kiosk")
            }}</router-link>
            <router-link to="/technology/fallDetector" @click="closeSideMenu">{{
              $t("fallDetector")
            }}</router-link>
            <router-link to="/technology/edgeBox" @click="closeSideMenu">{{
              $t("edgeBox")
            }}</router-link>
            <router-link to="/technology/mEdgeBox" @click="closeSideMenu">{{
              $t("mEdgeBox")
            }}</router-link>
          </div>
        </div>
        <div class="sidemenu4">
          <router-link to="/contact" @click="closeSideMenu">{{
            $t("contact2")
          }}</router-link>
        </div>
        <div class="sidemenu5">
          <a>LANGUAGE</a>
          <hr />
          <div class="sidemenu5-languages" @click="closeSideMenu">
            <div
              @click="changeLanguage('ko')"
              :class="{ active: activeLanguage === 'ko' }"
            >
              KOR
            </div>
            <div
              @click="changeLanguage('en')"
              :class="{ active: activeLanguage === 'en' }"
            >
              ENG
            </div>
            <div
              @click="changeLanguage('zh')"
              :class="{ active: activeLanguage === 'zh' }"
            >
              CHA
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeLanguage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeSideMenu: null,
    };
  },
  created() {
    // Set default language to Korean if no language is already set
    if (!this.activeLanguage) {
      this.changeLanguage("ko");
    }
  },
  methods: {
    closeSideMenu() {
      this.$emit("close");
    },
    toggleSideMenuDropdown(menu) {
      this.activeSideMenu = this.activeSideMenu === menu ? null : menu;
    },
    changeLanguage(lang) {
      this.$emit("language-changed", lang);
    },
  },
};
</script>

<style>
@import "../assets/css/sidemenu.css";
</style>
