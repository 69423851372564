// src/locales/ko.js
export default {
  //header, sidemenu
  company: "회사소개",
  solutions: "솔루션",
  technology: "연구·기술",
  contact2: "오시는 길",
  ceomessage: "CEO 메시지",
  ability: "기업역량",
  history: "기업이력",
  patent: "인증·특허",
  gfyjiggy: "지피지기",
  sleeptech: "슬립테크",
  electroceuticals: "전자약",
  kiosk: "키오스크",
  fallDetector: "낙상감지기",
  edgeBox: "엣지박스",
  mEdgeBox: "모바일엣지박스",
  contact: "오시는 길",

  //footer
  copyright: "Copyright © 2024 지피헬스. Inc., ALL RIGHTS RESERVED.",
  companyNameTitle: "회사명",
  companyname: "지피헬스(주)",
  registrationNum: "사업자등록번호",
  address:
    "경기스타트업캠퍼스, 경기도 성남시 분당구 판교로 289번길 20, 2동 8층 5호",
  tel: "전화",
  email: "이메일",

  //home
  home_header_title1:
    "모두의 건강을 위한 최선의 선택, 시간이 지나도 변하지 않는 건강의 기준이 되는 곳.",
  home_header_title2: " 지속적으로 당신의 웰빙을 보장하는, GFY를 경험해보세요.",
  home_title1: "지피헬스는 AI 기반 통합 헬스케어 솔루션으로",
  home_title2: "더 건강한 삶을 지원합니다",
  home_title3: "지피헬스는 혁신적인 헬스케어 솔루션을 통해",
  home_title4: "지속적인 성장을 목표로 합니다",

  home_mission1: "창업 아이템 중화권 진출 계획",
  home_mission1_1: "현지 업체와 합동 개발 및 시장 개척을 위한 MoU 체결",
  home_mission2: "창업 아이템 ‘디지털 치료제’ 승인을 통해",
  home_mission2_1: "전문 의료제품으로 확장 계획",
  home_mission3: "후속 아이템 단계적 출시를 통해",
  home_mission3_1: "향후 2년 내 3배 이상 매출 목표",
  home_mission4: "5년 내 IPO를 통한 Exit 계획",

  home_solutions1: "지피헬스가 제공하는",
  home_solutions2: "주요 솔루션을 만나보세요",
  home_gfyjiggy: "지피지기",
  home_gfyjiggyInfo: "인지장애(치매) 예방, 확인, 맞춤형 훈련 통합 서비스",
  home_sleeptech: "슬립테크",
  home_sleeptechInfo: "자체 개발한 촉각 센서를 응용한 스마트매트리스",
  home_electroceuticals: "전자약",
  home_electroceuticalsInfo: "당뇨 치료 효과를 목적으로 하는 미래형 의료기기",
  moreInfo: "더 알아보기",
  home_introduceTitle1: "지피헬스와 함께",
  home_introduceTitle2: "이야기를 만들어가세요",
  home_companyIntroduce: "회사소개",
  home_companyIntroduceInfo1: "지피헬스는 고객과 함께 ",
  home_companyIntroduceInfo2: "성장하고 성공하는 기업이 되겠습니다.",
  home_contactIntroduce: "오시는 길",
  home_contactIntroduceInfo1: "지피헬스에서 자세한 위치를 ",
  home_contactIntroduceInfo2: "편하게 올 수 있도록 안내해 드립니다.",

  //ceo message
  ceomessage_title1: "안녕하십니까?",
  ceomessage_title2: "지피헬스(주)의 대표이사 이태현입니다.",
  ceomessage_paragraph1_1:
    "우선, 저희저희 지피헬스에 관심을 가져주시고 방문해 주신 모든 분들께 깊은 감사의 인사를 드립니다.",
  ceomessage_paragraph1_1_1:
    "지피헬스는 헬스케어 분야에서 혁신적인 솔루션과 최상의 서비스를 제공하기 위해 항상 최선을 다하고 있습니다.",
  ceomessage_paragraph1_2:
    "저희의 목표는 건강과 삶의 질을 향상시키는 것에 있으며, 이를 위해 최신 기술과 연구를 기반으로 한 헬스케어 제품과 서비스를 개발하고 있습니다.",
  ceomessage_paragraph2_1:
    "지피헬스는 고객 여러분의 신뢰와 사랑을 바탕으로 성장해왔습니다.",
  ceomessage_paragraph2_2:
    "앞으로도 고객의 목소리에 귀 기울이며, 건강한 삶을 위한 새로운 가치를 창출해 나가겠습니다. ",
  ceomessage_paragraph2_2_2:
    "저희는 단순히 제품을 제공하는 것에 그치지 않고, 고객의 삶의 질을 높이는 데 기여할 수 있는 헬스케어 파트너로서 역할을 다할 것을 약속드립니다.",
  ceomessage_paragraph3:
    "앞으로도 변함없는 성원과 관심 부탁드리며, 여러분의 건강하고 행복한 삶을 위해 지피헬스가 항상 함께 하겠습니다.",
  ceomessage_paragraph4: "감사합니다.",
  ceomessage_paragraph5: "지피헬스(주) 대표이사 ",
  ceomessage_paragraph6: "이태현",

  //ability
  ability_img: "AI / HW 기반 통합 라이프(헬스)케어 솔루션",
  ability_b2g1: "창업 아이템",
  ability_b2g2: "치매 예방/훈련 프로그램 App.",
  ability_b2g3: "고성능 노인 위치 감지기",
  ability_b2g4: "NFC 탑재 손톱 부착형 어르신 인식표",
  ability_b2g5: "요양 노인용 낙상감지기",
  ability_b2g6: "[신규] 장기 요양자 대상 스마트 매트",
  ability_b2b1: "근로자 통합 건강관리 키오스크",
  ability_b2b2: "작업 중 심장이상 예측 스마트 패치",
  ability_b2b3: "작업 환경 모니터링용 엣지 박스",
  ability_b2b4: "작업 현장용 낙상감지기",
  ability_b2c1: "[신규] 당뇨병 개선 전자약 시스템",
  ability_b2c2: "이끼 필터 공기청정기",
  ability_b2c3: "수면질 향상 스마트 매트",

  //history
  history_2019_1: "지피헬스(주) 법인설립",
  history_2019_2: "2020년 초기 창업 패키지 최우수 과제 선정",
  history_2019_2_1: "'인공지능 기반 지능형 치매 평가 재활 훈련용 앱'",
  history_2020_1: "2020년 중소벤처기업부 지원 과제 선정",
  history_2020_2: "'고장예지보전기능을 갖는 지능형재난예경보시스템'",
  history_2021_1: "밴처기업인증",
  history_2021_2: "기업부설연구소",
  history_2021_3: "2021년 산업자원부 지원 과제 선정",
  history_2021_3_1:
    "'산업 직군 행동 패턴 분석 기반의 개인 맞춤형 근로자 건강관리 솔루션'",
  history_2023_1: "전문연구 사업자 등록",
  history_2023_2: "2023년 디자인 혁신 지원 사업 선정",
  history_2023_2_1: "'당뇨 개선 전자약 적용을 위한",
  history_2023_2_2: "비침습 귀신경 자극 장치'",
  history_2023_3: "신규 사업 론칭",
  history_2023_3_1: "당뇨병 개선 전자약 시스템",
  history_2023_3_2: "배회 감지 시스템",
  history_2023_3_2_1: "- 노인 돌봄 시니어 케어 서비스",
  history_2023_3_2_2: "- 고독사 예방(스마트 메트리스)",
  history_2024_1: "2024 미래융합기술 파이오니어 사업 선정",
  history_2024_1_1: " ‘미세유체 장치 연동 미세중력 모사 시스템 개발’",

  //patent
  patent1: "사업자등록증",
  patent2: "벤처기업확인서",
  patent3: "기업부설연구소 인정서",
  patent4: "전문연구사업자 신고증",
  patent5: "물의 섭취량 관리가 가능한",
  patent5_1: "스마트 물병 및 그 제어방법",
  patent6: "길이 단축형 휴대용 자동주사 장치",
  patent7: "반려 로봇을 이용한 실버 케어 시스템",
  patent8: "무선 골격근 자극에 관한",
  patent8_1: "패턴 전송 방법",
  patent9: "압저항 촉각센서를 이용한",
  patent9_1: "노약자 관리 시스템",
  patent10: "치매 환자 관리 방법 및 장치",
  patent11: "인공지능에 기반한",
  patent11_1: "치매 진단 방법 및 장치",
  patent12: "전자약 시스템 및 건강관리",
  patent12_1: "서비스 기술",
  patent13: "당뇨 개선 전자약 시스템 및 방법",

  //contact
  location: "오시는 길",
  addressTitle: "주소",
  address1: "경기스타트업캠퍼스,",
  address2: "경기도 성남시 분당구 판교로 289번길 20, 2동 8층 5호",
  transpotationTitle: "대중교통",
  subwayTitle: "지하철",
  subwayInfo: "신분당 판교역 4번출구에서",
  subwayInfo2: "판교테크노벨리 방향 버스 승차",
  busTitle: "버스",
  busInfo: "봇들육교 또는 삼평교 정류장 하차",
  busInfo2: "",

  //gfyjiggy
  gfyjiggy_section1_title: "지피지기란?",
  gfyjiggy_section1_info1:
    "국내 우수 병원과 연구기관의 기술을 이전 받아 인공지능 IoT 솔루션 전문 업체 지피헬스(주)가 개발한 인지장애(치매) 예방, 확인,",
  gfyjiggy_section1_info2: "맞춤형",
  gfyjiggy_section1_info3: "훈련 통합 서비스",
  gfyjiggy_section1_info4: "정부지원 R&D를 통한 기술 고도화",
  gfyjiggy_section2_title: "지피지기의 차별성",
  gfyjiggy_section2_info1: "세분화 된 인지 능력 평가를 통한 맞춤형 훈련 추천",
  gfyjiggy_section3_title: "지피지기의 효과",
  gfyjiggy_section3_info1: "맞춤형 분석을 통해 떨어진 기능 집중 훈련",
  gfyjiggy_section3_subtitle1: "환자 관리",
  gfyjiggy_section3_subtitle1_info:
    "치료사는 환자의 문제를 파악하고 효과적으로 해결하기 위해 환자의 치매 예방과 개선을 돕습니다.",
  gfyjiggy_section3_subtitle2: "단일 훈련",
  gfyjiggy_section3_subtitle2_info:
    "환자의 치매 상황에 따라 특정한 단일 훈련만 반복적으로 수행할 수 있도록 돕습니다.",
  gfyjiggy_section3_subtitle3: "구성 훈련",
  gfyjiggy_section3_subtitle3_info:
    "환자의 치매 상황에 맞게 다양한 훈련을 조합하여 복합적인 훈련을 수행할 수 있도록 돕습니다.",
  gfyjiggy_section3_subtitle4: "환자 기록지",
  gfyjiggy_section3_subtitle4_info:
    "각 훈련 결과를 기록하여 의사가 치매 상황에 보다 정확하게 확인할 수 있도록 돕습니다.",
  gfyjiggy_section4_title: "치매 예방, 진단, 개선 서비스",
  gfyjiggy_section4_subtitle: "맞춤형 분석을 통해 떨어진 기능 집중 훈련 가능!",
  gfyjiggy_section4_startbtn: "다운로드 하기",

  //sleeptech
  sleeptech_section1_title: "슬립테크란",
  sleeptech_section1_info: "자체 개발한 촉각 센서를 응용한 스마트매트리스",
  sleeptech_section2_title: "촉각센서(tactile sensor) 특장점",
  sleeptech_section2_info1: "proportional 한 저항 변화로 검출하는 기술 적용",
  sleeptech_section2_info2:
    "저항을 Force Sensor 접촉면에 dot 형태로 분포시켜 이동 경로, 변화량 인식 가능한 센서 설계",
  sleeptech_section2_info3:
    "접촉하는 힘의 세기 및 분포 분석을 시간 단위로 저장하여, 힘의 하중을 분석 비교할 수 있는 H/W, S/W 설계",
  sleeptech_section2_info4:
    "전도성 물질 제어, 전도성 잉크 제조 및 압저항 센서 설계",
  sleeptech_section2_info5:
    "사용자의 용도에 맞게 크기, 하중 범위, 정밀도 조절 가능",
  sleeptech_section3_title: "슬립테크 특징",
  sleeptech_section3_info1_1: "크기, 정밀도 customize 가능한",
  sleeptech_section3_info1_2: "필름형 촉각센서",
  sleeptech_section3_info2: "부분 하중과 하중의 변화 측정이 필요한 사업 분야",
  sleeptech_section3_info3_1: "매트리스와 접촉 여부와 위치 파악",
  sleeptech_section3_info3_2: "누운 시간과 움직임 정도 확인",
  sleeptech_section4_title: "슬립테크 서비스 목표",
  sleeptech_section4_subtitle: "독거 가구 건강 관리 서비스",
  sleeptech_section4_info1: "1인 가구 안전",
  sleeptech_section4_info1_1: "고독사 초동 감지, 낙상 감지",
  sleeptech_section4_info2: "노인복지 용품",
  sleeptech_section4_info2_1: "욕창방지, 원격모니터링",
  sleeptech_section4_info3: "수면 상태 움직임",
  sleeptech_section4_info3_1: "모니터링 수면 건강 용품",

  //electroceuticals
  electroceuticals_title1: "인공지능 적용 앱",
  electroceuticals_title2: "과",
  electroceuticals_title3: "전자약 기술",
  electroceuticals_title4: "을 활용한",
  electroceuticals_title5: "맞춤형 다이어트 시스템",
  electroceuticals_section1_title:
    "체질과 생활 방식 분석하여 맞춤형 전자약 솔루션 제공",
  electroceuticals_section2_title:
    "통합 앱과 연동 하드웨어를 통해 개인 정보 입력 및 분석",
  electroceuticals_section3_title: "전자약 기반 맞춤형 다이어트 웨어러블 추천",
  electroceuticals_section4_title: "식욕관리 웨어러블",
  electroceuticals_section4_info:
    "배고픔 호르몬(ghrelin) 조절과 식욕 억제 효과",
  electroceuticals_section5_title: "혈당관리 웨어러블",
  electroceuticals_section5_info:
    "미주 신경(vagus nerve) 자극과 혈당 변동성 완화 효과",
  electroceuticals_section6_title: "지방관리 웨어러블",
  electroceuticals_section6_info:
    "미세전류(microcurrent stimulation) 자극과 지방생성 억제 효과",
  electroceuticals_section7_title1: "정상 체중 및 혈당 이후",
  electroceuticals_section7_title2: "후속 관리를 위한",
  electroceuticals_section7_title3: "유지 관리 프로그램",
  electroceuticals_section7_icon1_title: "운동 프로그램",
  electroceuticals_section7_icon1_info: "맞춤형 운동 프로그램 추천 및 안내",
  electroceuticals_section7_icon2_title: "식단 프로그램",
  electroceuticals_section7_icon2_info:
    "정상 체중과 혈당 유지를 위한 식단 프로그램 안내",
  electroceuticals_section7_icon3_title: "수면 프로그램",
  electroceuticals_section7_icon3_info:
    "건강 유지를 위한 최적의 수면 프로그램 안내",

  //kiosk
  kiosk_title: "키오스크",
  kiosk_section1_title: "건강관리 키오스크란",
  kiosk_section1_info1:
    "건설업, 제조업, 운수업 종사자의 출퇴근 시 개인 ID 확인, 매일 건강 상태 확인을 위한 간단한 문진에 응용, 매일 매주 추이 변화를 관찰할 수 있음",
  kiosk_section1_info2:
    "노인과 노약자, 일반인의 평소 건강 상태를 측정, 기록하여, 이상 상태 조기 발견",
  kiosk_section1_info3:
    "근로자 안전사고를 예방할 수 있도록 안내함. 예를 들면, 음주 측정 시 혹은 다른 측정값이 평소와 다르거나 기준에서 벗어날 경우, 주의/경고, 휴식 권유, 작업 제외 등 작업 시 주의사항 안내",
  kiosk_section2_title: "주요 측정 항목",
  kiosk_section2_icon1: "신장",
  kiosk_section2_icon2: "체중",
  kiosk_section2_icon3: "체온",
  kiosk_section2_icon4: "혈압",
  kiosk_section2_icon5: "체지방(체수분)",
  kiosk_section2_icon6: "혈중산소 포화도",
  kiosk_section3_title: "주요 기능 및 사용 형태",
  kiosk_section3_icon1: "터치스크린",
  kiosk_section3_icon2: "음성안내",
  kiosk_section3_icon3: "음주측정기 별도 부착",
  kiosk_section3_icon4: "통신(WiFi, Bluetooth 등)",
  kiosk_section4_title: "응용사업 분야",
  kiosk_section4_info1: "건설업 제조업 등",
  kiosk_section4_info1_1: "모든 산업 직군의 작업자 건강관리",
  kiosk_section4_info1_2: "",
  kiosk_section4_info2: "노인 노약자 일반인의 자체 검사 건강관리",
  kiosk_section4_info3:
    "측정항목 – 키, 몸무게, 체지방/체질량, 혈압, 산소포화도, 알코올 여부, 이외에도 측정 항목 추가 또는 제외 가능",
  kiosk_section5_title: "성능인증서",

  //fallDetector
  fallDetector_title: "스마트 낙상감지기",
  fallDetector_section1_title: "스마트 낙상감지기란",
  fallDetector_section1_info1:
    "스마트 낙상 감지기는 사람의 갑작스러운 자세 변화를 감지하여 장기적인 부상 위험을 줄이는 데 도움이 되는 장치",
  fallDetector_section1_info2:
    "야외에서 긴급하고 불규칙한 작업을 하는 건설, 제조, 물류배송, 119구급대원의 사고에 용이",
  fallDetector_section1_info3:
    "낙상 사고 발생시, 이른 시간 안에 주변에 알리고, 반복적으로 일어나는 장소를 파악하여, 위험을 경고하여 반복되는 사고를 막는 데 도움이 됨",
  fallDetector_section2_title: "스마트 낙상감지기 데이터 저장 방법",
  fallDetector_section2_info1: "내장 flash memory 사용, Acc/Gyro data 저장",
  fallDetector_section2_info2:
    "한 번의 event 발생할 때마다 전후 1.65초~3.3초 data 165~330개의 Acc/Gyro data 저장시 최대 31개의 event 저장, 3.3초 ~ 6.6 초 적용시 최대 15개의 event 저장",
  fallDetector_section2_info3:
    "serial flash memory 예비로 추가 (내장 flash memory 용량보다 더 큰 data 요구시)",
  fallDetector_section2_info4:
    "휴대폰에서 주기적(1시간 또는 2시간 간격)으로 data를 가져갈 때 15개의 events 저장",
  fallDetector_section3_title: "데이터 송신 응용소프트웨어 '나의건강수첩'",
  fallDetector_section3_info1:
    "일상생활에서 취득한 필요한 개인정보를 낙상감지기에서 원하는 서버로 전송하는 응용소프트웨어",
  fallDetector_section3_info2:
    "낙상감지기에서 수집된 낙상 결과값을 자동으로 저장하고, 저장된 데이터를 일정한 시간에 자동 혹은 수동으로 스마트폰의 응용프로그램을 통해 원하는 서버에 전달",
  fallDetector_section3_info3:
    "일간 주간 등 기간별 데이터를 응용소프트웨어를 통해 확인하고 개인 스스로 건강관리에 활용",
  fallDetector_section3_info4:
    "낙상사고 발생시 감지하여 자동 저장, 송신하여 빈번하게 일어나는 장소 파악 가능",
  fallDetector_section3_info5:
    "낙상사고 발생 실시간 감지, 주변 알림, 저장, 송신, 제3자에게 전달하기 위해선 server와 실시간 통신 연동이 필요함",
  fallDetector_section4_title: "응용 사업분야",
  fallDetector_section4_info1: "낙상 위험이 있는 ",
  fallDetector_section4_info1_1: "모든 산업 직군의 근로자",
  fallDetector_section4_info2:
    "노인 노약자 환자 같은 거동이 불편한 분들, 낙상사고 발생시 피해가 클것으로 예상되는 그룹 ",
  fallDetector_section5_title: "성능인증서",

  //edgeBox
  edgeBox_title: "환경센서 엣지박스",
  edgeBox_section1_title: "환경센서 엣지박스란",
  edgeBox_section1_info1:
    "제조, 건설 등 옥외 산업현장에서 고정형 작업 환경 측정(온/습도,미세먼지, CO2, 유해가스 등)",
  edgeBox_section1_info2:
    "벽면에 고정 부착하여, 작업 환경에서 지속적으로 수집되고 저장된 데이터를 엣지박스 자체에서 1차 분석하고, 수집된 데이터를 서버로 전송하여 작업 환경을 분석할 수 있게 함",
  edgeBox_section1_info3:
    "작업 환경 관리자 감시 시스템을 통해 위험요인 인지에 따른 가이드 제시(작업 일정표 변동, 작업량 조정, 휴식 시간 부여 등)",
  edgeBox_section2_title: "환경센서 엣지박스 동작방법",
  edgeBox_section3_title: "엣지박스 기능",
  edgeBox_section3_icon1: "테이터 중계 및 암호화",
  edgeBox_section3_icon2: "리눅스 서버 SSH 자동화",
  edgeBox_section3_icon3: "PLC 원격 제어 및 데이터 수집",
  edgeBox_section3_icon4: "WAS 서버",
  edgeBox_section3_icon5: "유/무선 통신",
  edgeBox_section4_title: "응용 사업분야",
  edgeBox_section4_info1: "건설업 제조업 등 ",
  edgeBox_section4_info1_1: "모든 산업 직군",
  edgeBox_section4_info2: "위험 환경요인에 노출된 실외 작업장",
  edgeBox_section4_info3: "환기가 필요한",
  edgeBox_section4_info3_1: "실내 밀폐 공간",
  edgeBox_section4_info4:
    "주변 환경, 이산화탄소, 온도, 습도, TVOC, 미세먼지(PM10, PM2.5), CCTV(optional) 측정이 필요한 분야. 필요시 측정항목 추가/제외 가능",
  edgeBox_section5_title: "성능인증서",

  //mEdgeBox
  mEdgeBox_title: "환경센서 모바일엣지박스",
  mEdgeBox_section1_title: "환경센서 모바일엣지박스란",
  mEdgeBox_section1_info1:
    "119구급대, 차량 등 옥외 산업현장에서 이동형 작업 환경 측정(온/습도, 미세먼지, CO2, 유해가스)",
  mEdgeBox_section1_info2:
    "이동형, 소형, 탈부착이 가능한 분리형 엣지박스, 상대적으로 간단하고 짧은 시간 측정 또는 이동하는 근로자의 작업 환경 측정에 유리",
  mEdgeBox_section1_info3:
    "고정형 엣지박스 GHEB 시리즈에 비해, 이동하는 근로자의 환경요소를 측정할 수 있는 장점",
  mEdgeBox_section1_info4:
    "차량 내부 같은 필요한 위치에 부착하여 환경요소를 측정하고, 이동형 메모리카드를 분리하여 작업 환경에서 지속적으로 수집되고 저장된 데이터를 서버로 전송하여 작업 환경을 분석할 수 있게 함",
  mEdgeBox_section1_info5:
    "작업 환경 관리자 감시 시스템을 통해 위험요인 인지에 따른 가이드 제시(작업 일정표 변동, 작업량 조정, 휴식 시간 부여 등)",
  mEdgeBox_section2_title: "환경센서 모바일엣지박스 동작방법",
  mEdgeBox_section3_title: "모바일엣지박스 기능",
  mEdgeBox_section3_info1:
    "차량의 220V 공급은 시동을 끄면 같이 꺼지는 것으로 가정하여, 예비 전력 사용",
  mEdgeBox_section3_info2:
    "상시 전원 공급이 가능하다면 미니 PC 또는 센서 모듈의 소비전력 측정하여, 얼마 동안 상시전원 공급이 가능한지 계산 필요. (AC 전력 측정기 별도)",
  mEdgeBox_section3_info3:
    "사진을 저장하지 않는다면 수십 년 분량의 data도 저장 가능",
  mEdgeBox_section3_info4:
    "정해진 data 전송시간에 미니 PC를 반드시 network에 연결해 놓아야 함",
  mEdgeBox_section4_title: "응용 사업분야",
  mEdgeBox_section4_info1: "119구급차 활동 지역의 내부/외부, 화물차 운전자 등 ",
  mEdgeBox_section4_info1_1: "차량 이동형 작업 환경 엣지박스",
  mEdgeBox_section4_info1_2: "",
  mEdgeBox_section4_info2: "환기가 필요한 차량 밀폐 공간 환경 측정",
  mEdgeBox_section4_info3: "현장 상황에 맞게 즉시 설치와 수거",
  mEdgeBox_section5_title: "성능인증서",
};
