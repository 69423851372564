<template>
  <hr class="footer-line" />
  <div :class="['footer-section', languageClass]">
    <div class="footer-left-section">
      <div class="footer-img-container">
        <img src="../assets/gfyhealth_logo2.png" />
      </div>
      <div class="footer-copyright">
        Copyright 2024. Gfyhealth INC. All rights reserved.
      </div>
    </div>
    <div class="footer-right-section">
      <div class="footer-info1">
        <div class="footer-info1-title">INFORMATION</div>
        <div>
          <div class="footer-info1-subtitle">{{ $t("companyNameTitle") }}</div>
          <div class="footer-info1-text1">{{ $t("companyname") }}</div>
        </div>
        <!-- <div>
          <div class="footer-info1-subtitle">{{ $t("registrationNum") }}</div>
          <div class="footer-info1-text">186-81-01597</div>
        </div> -->
      </div>
      <div class="footer-info2">
        <div class="footer-info2-title">ADDRESS</div>
        <div class="footer-info2-text">
          <!-- {{ $t("address") }} -->
          {{ $t("address1") }} <br />
          {{ $t("address2") }}
        </div>
      </div>
      <div class="footer-info3">
        <div class="footer-info3-title">CONTACT</div>
        <div>
          <div class="footer-info3-subtitle">{{ $t("tel") }}</div>
          <div class="footer-info3-text1">+82-70-7720-9874</div>
        </div>
        <div>
          <div class="footer-info3-subtitle">{{ $t("email") }}</div>
          <div class="footer-info3-text">ceo@gfyhealth.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    languageClass() {
      return `lang-${this.$i18n.locale}`;
    },
  },
};
</script>

<style>
@import "../assets/css/footer.css";
</style>
